<template>
  <div>
    <v-autocomplete
      :label="label || $t('customer')"
      v-model="customer"
      :items="customers"
      item-value="uuid"
      item-text="phone"
      return-object
      @change="selectCustomer"
      :search-input.sync="customerSearch"
      :filter="customerFilter"
      :disabled="disabled"
      :filled="filled"
      :solo="solo"
      :hide-details="hideDetails"
      :dense="dense"
    />
  </div>
</template>

<script>

  export default {
    name: 'CustomerFields',
    components: {
    },
    props: {
      customer: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        showCreateCustomer: false,
        customerSearch: null,
        newCustomer: {
          ssn: null,
          name_line: null
        }
      }
    },
    mounted() {

    },
    computed: {

    },
    methods: {

    },
    watch: {

    }
  }
</script>

<style scoped>

</style>